<template>
  <div>
    <div class="content">
      <div style="margin: auto 0; width: 409px">
        <!-- <img src="@/assets/logo.png" alt /> -->
        <img src="@/assets/img/image_home_logo.png" alt />
      </div>
      <div class="header">
        <div class="nav-0">
          <div class="f-sa nav-1">
            <!-- <span>帮助反馈</span> -->
            <span style="position: relative" class="hov-0">
              家长群
              <div>
                <div class="qun">
                  <p style="height: 3px"></p>
                  <div>
                    <a href="">高三家长QQ群</a>

                    <a v-for="(item, index) in qunList" :key="index" target="_blank" :alt="item.groupNumber" :title="item.groupNumber"
                      :href="item.groupUrl">{{ item.groupArea }}</a>

                    <h6 class="clearfix"></h6>
                  </div>
                </div>
              </div>
            </span>
            <span style="position: relative" class="hov-1">OK志愿
              <!-- <img class="mask" src="@/assets/firstPage/小程序.png" alt="" /> -->
              <img class="mask" :src="$store.state.QRcode[0]" :onerror="smallImgError" alt="" />
              <!-- <img class="mask" :onerror="smallImgError" style="width:98px" :src="QRcode[0]" alt="" /> -->
            </span>
            <span class="hov-2" style="position: relative">关注我们

              <img class="mask" :onerror="smallImgError" style="width: 98px" :src="QRcode1[0]" alt="" />
            </span>
            <span style="margin-right: 10px" href="" @click="gologin" v-if="!islogin">去登录</span>
            <span class="userName" style="margin-left: 30px" v-else>{{ userName }}
              <span class="maskdiv">
                <span @click="gopersonalCenter">个人中心</span>
                <span @click="loginOut">退出登录</span>
              </span>
            </span>
          </div>
        </div>
        <div class="clearbox"></div>
        <div class="logo">
          <div class="nav">
            <span :class="{ active: $route.path.indexOf('index') == 1 }" @click="handClick(1, '')">首页</span>
            <span :class="{ active: $route.path.indexOf('information') == 1 }" @click="
              () => {
                this.active = 2;
                this.$router.push('/information');
              }
            ">旭德简介</span>
            <span :class="{ active: $route.path.indexOf('volunterservce') == 1 }"
              @click="handClick(3, 'volunterservce')">志愿服务</span>
            <span :class="{ active: $route.path.indexOf('dataQuery') == 1 }" @click="
              () => {
                this.active = 4;
                this.$router.push('/dataQuery');
              }
            ">数据查询</span>
            <span :class="{ active: $route.path.indexOf('lectureHall') == 1 }"
              @click="handClick(5, 'lectureHall')">高考讲堂</span>
            <span :class="{
              active:
                $route.path.indexOf('dynamicsOfCollegeEntranceExamination') ==
                1,
            }" @click="handClick(6, 'dynamicsOfCollegeEntranceExamination')">高考动态</span>
            <span :class="{ active: active == 7 }" class="phone">
              <img class="mask" :onerror="smallImgError" :src="QRcode[0]" style="width: 98px" alt="" />

              <img style="position: absolute; left: 8px; top: 33%" src="@/assets/firstPage/手机版.png" alt="" />
              手机版
            </span>
          </div>
          <!-- <el-input
        v-model="formdata.search"
        placeholder="搜索内容"
        style="width: 30%; margin-right: 10%"
        suffix-icon="el-icon-search"
      ></el-input> -->
        </div>
      </div>
    </div>
    <div>
      <!-- <FirstPage></FirstPage> -->
    </div>
    <div v-if="1" style="width: 100%; margin: 0 auto">
      <!-- <keep-alive> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
    </div>

    <!-- <div class="content-1" v-else>
      <div v-if="active == 1">
        <FirstPage></FirstPage>
      </div>
      <div v-if="active == 2"></div>
      <div v-if="active == 3"></div>
      <div v-if="active == 4"></div>
      <div v-if="active == 5">
        <LectureHall></LectureHall>
      </div>
      <div v-if="active == 6">
        <DynamicsOfCollegeEntranceExamination></DynamicsOfCollegeEntranceExamination>
      </div>
    </div> -->
    <footer class="">
      <div style="background: black">
        <div style="width: 1400px; margin: 0 auto">
          <div class="f-row" style="padding: 30px 0; width: 100%">
            <div style="
                width: 50%;
                padding-right: 10%;
                border-right: 1px solid #cccccc;
              " class="f-sb">
              <div class="f-col">
                <p>关于旭德</p>
                <router-link to="/information/introduction">
                  旭德简介</router-link>
                <router-link to="/information/contactus">联系我们</router-link>
                <router-link to="/Privacypolicy">隐私政策</router-link>
                <router-link to="/Useragreement">用户协议</router-link>
              </div>
              <div class="f-col">
                <p>其它通道</p>
                <router-link to="">专家入口</router-link>
                <router-link to="">员工入口</router-link>
                <router-link to="">考试入口</router-link>
              </div>
              <div class="f-col">
                <p>关注我们</p>
                <img :onerror="smallImgError" :src="QRcode1[0]" style="width: 98px" alt="" />
              </div>
              <div class="f-col">
                <p>小程序</p>
                <img :onerror="smallImgError" :src="QRcode[0]" style="width: 98px" alt="" />
              </div>
              <div class="f-col">
                <p>APP</p>
                <img :onerror="smallImgError" src="../../assets/img/app_code.jpg" style="width: 100px" alt="" />
              </div>
            </div>

            <div class="" style="margin-left: 5%;margin-right: 5%">
              <div style="
                  width: 65px;
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #ffffff;
                  margin-top: 30px;
                  line-height: 40px;
                ">
                全国热线
              </div>
              <p style="
                  height: 22px;
                  font-size: 28px;
                  font-family: Microsoft YaHei;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 40px;
                ">
                4001-517-985
              </p>
            </div>
            <div @click="handlePictureCardPreview">
              <div><img src="../../assets/chubanwu.png" alt="" style="width: 200px"></div>
              <p style="
                  width:200px;
                  text-align:center;

                ">出版物经营许可证</p>
            </div>
          </div>
        </div>
        <div class="tail">
          Copyright 2013-{{ nowyear }} www.okzhiyuan.com All rights reseved
          重庆旭德教育软件股份有限公司
          <a href="https://beian.miit.gov.cn" target="_blank"> 备案号：渝ICP备14009702号-5 </a>
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010602501115" target="_blank">
            渝公安网备：50010602503063 </a>
        </div>
      </div>
    </footer>
    <div class="sidebar">
      <table>
        <!-- <tr>
          <td id="contactForm">
            <a>
              <div>
                <img src="@/assets/fiexd/lianxi.png" />
                <img
                  class="active"
                  src="@/assets/fiexd/lianxi2.png"
                />
              </div>
              联系我们
            </a>
          </td>
        </tr> -->
        <tr>
          <td>
            <a href="">
              <div>
                <img src="@/assets/fiexd/code.png" />
                <img class="active" src="@/assets/fiexd/code2.png" />
                <img class="active2" :onerror="smallImgError" :src="QRcode[0]" />
              </div>
              关注微信
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a>
              <div>
                <img src="@/assets/fiexd/kefu.png" />
                <img class="active" src="@/assets/fiexd/kefu2.png" />
                <div class="active3">
                  <div class="openUrl" style="width: 100%"
                    @click="go('http://wpa.qq.com/msgrd?v=3&uin=2300885791&site=qq&menu=yes')">
                    <img class="active" src="@/assets/fiexd/kefu3.png" />
                    <h5>QQ:2300-885-791</h5>
                  </div>
                  <div class="openUrl" style="width: 100%"
                    @click="go('http://wpa.qq.com/msgrd?v=3&uin=2916385493&site=qq&menu=yes')">
                    <img class="active" src="@/assets/fiexd/kefu3.png" />
                    <h5>QQ:2916-385-493</h5>
                  </div>
                  <div class="openUrl" style="width: 100%"
                    @click="go('http://wpa.qq.com/msgrd?v=3&uin=2556349838&site=qq&menu=yes')">
                    <img class="active" src="@/assets/fiexd/kefu3.png" />
                    <h5>QQ:2556-349-838</h5>
                  </div>
                  <div class="openUrl" style="width: 100%"
                    @click="go('http://wpa.qq.com/msgrd?v=3&uin=2264574775&site=qq&menu=yes')">
                    <img class="active" src="@/assets/fiexd/kefu3.png" />
                    <h5>QQ:2264-574-775</h5>
                  </div>
                </div>
              </div>
              在线客服
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="#">
              <div>
                <img src="@/assets/fiexd/xiangshang.png" />
                <img class="active" src="@/assets/fiexd/xiangshang2.png" />
              </div>
              返回顶部
            </a>
          </td>
        </tr>
      </table>
    </div>
    <el-dialog :visible.sync="dialogVisibleImg">
      <img width="100%" src="../../assets/chubanwu.png" alt="">
    </el-dialog>
    <!-- 登录二维码 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="350px" style="height: 600px" @closed="closedialog">
      <div style="height: 270px">
        <img class="img" :onerror="smallImgError" :src="imgsrc" alt="" />
      </div>
      <div style="text-align: center; margin-top: 20px">{{ scanHint }}</div>
    </el-dialog>
  </div>
</template>
<script>
// import QRcode from "@/util/global";
import axios from "axios";
import { getAdvertisementDetail } from "@/api";
import { getQQGroupList } from "@/api/index.js"
import { getLoginQrcode, getQrcodeLoginInfo } from "@/api/login.js";
export default {
  name: "Home",
  data() {
    return {
      dialogVisibleImg: false,
      interval: null,
      QRcode: [],
      QRcode1: [],
      dialogVisible: false,
      formdata: {},
      active: "1",
      islogin: false,
      userName: "",
      nowyear: 2022,
      imgsrc: "", //登录二维码
      scanHint: "请使用微信或OK志愿小程序扫一扫登录", //是否扫描
      userId: "",
      qunList: []
    };
  },
  mounted() {
    let date = new Date();
    this.nowyear = date.getFullYear();
    // console.log(QRcode);
    this.getQRcode();
    this.getQQGroupList();

    let student1 = sessionStorage.getItem("student") || {};
    let student = JSON.parse(student1);
    this.userId = student.userId || "";
    let sessionId = sessionStorage.getItem("sessionId") || null;
    if (sessionId) {
      this.islogin = true;
    }
    let sysUser1 = sessionStorage.getItem("sysUser") || {};
    let sysUser = JSON.parse(sysUser1);
    if (student.realName) {
      this.userName = student.realName;
    } else {
      this.userName = sysUser.userName || "用户名";
    }
  },
  methods: {

    getQQGroupList() {
      getQQGroupList().then((res) => {
          if(res.code == 1 && res.data){
            this.qunList = res.data
          }
      })
    },

    handlePictureCardPreview() {
      this.dialogVisibleImg = true;
    },
    go(url) {
      window.open(url)
    },
    // 退出登录
    loginOut() {
      this.userName = "";
      sessionStorage.clear();
      this.islogin = false;
      window.location.reload()
    },
    closedialog() {
      console.log("停止");
      clearInterval(this.interval);
    },
    // 小程序图片
    async getQRcode() {
      let res = await getAdvertisementDetail({ id: 8 });
      let res1 = await getAdvertisementDetail({ id: 7 });
      if (res.code == 1) {
        this.QRcode = res.data.images;
        this.$store.commit("setQRcode", this.QRcode);
      }
      if (res1.code == 1) {
        this.QRcode1 = res1.data.images;
      }
    },
    handClick(val, path) {
      this.active = val;
      this.$router.push("/" + path);
    },
    gopersonalCenter() {
      this.$router.push({
        path: "/personalCenter/personalInformation",
        query: { userId: this.userId },
      });
    },
    closedialog() {
      console.log("停止");
      clearInterval(this.interval);
    },
    scanlogin(code) {
      this.interval = setInterval(() => {
        axios
          .get("/mobile/user/getQrcodeLoginInfo", {
            params: {
              code: code,
            },
          })
          .then((result) => {
            if (this.dialogVisible == false) {
              clearInterval(this.interval);
            }
            result = result.data;
            console.log(result);
            if (result.code == 1) {
              let data1 = result.data || {};
              if (data1.type == 1) {
                this.scanHint = "已扫码";
              } else if (data1.type == 2) {
                this.scanHint = "同意登录";
                if (data1.sessionId) {
                  clearInterval(this.interval);
                  this.userId = data1.student.userId || "";
                  this.$store.commit("setUserId", this.userId);
                  if (data1.student.realName) {
                    this.userName = data1.student.realName;
                  } else {
                    this.userName = data1.sysUser.userName || "用户名";
                  }
                  this.dialogVisible = false;
                  let sessionId = data1.sessionId || "";
                  if (sessionId) {
                    this.islogin = true;
                  }
                  window.sessionStorage.setItem("sessionId", sessionId);
                  let sysUserdata = data1.sysUser || {};
                  let sysUser = JSON.stringify(sysUserdata);
                  window.sessionStorage.setItem("sysUser", sysUser);
                  let studentdata = data1.student || {};
                  let student = JSON.stringify(studentdata);
                  window.sessionStorage.setItem("student", student);
                  window.location.reload()
                }
              }
            }
          });
        // getQrcodeLoginInfo({ code: code }).then((result) => {
        //   if (result.code == 1) {
        //     console.log("登录成功");

        //     let data1 = result.data || {};
        //     if (data1.type == 1) {
        //       this.scanHint = "已扫码";
        //     } else if (data1.type == 2) {
        //       this.scanHint = "同意登录";
        //       if (data1.sessionId) {
        //         clearInterval(interval);
        //         this.dialogVisible = false;
        //         this.islogin = true;
        //         let sessionId = data1.sessionId || "";
        //         window.sessionStorage.setItem("sessionId", sessionId);
        //         let sysUserdata = data1.sysUser || {};
        //         let sysUser = JSON.stringify(sysUserdata);
        //         window.sessionStorage.setItem("sysUser", sysUser);
        //         let studentdata = data1.student || {};
        //         let student = JSON.stringify(studentdata);
        //         window.sessionStorage.setItem("student", student);
        //       }
        //     }
        //   }
        // });
      }, 500);
    },
    gologin() {
      this.dialogVisible = true;
      getLoginQrcode().then((res) => {
        if (res.code == 1) {
          console.log(res);
          let data = res.data || {};
          this.imgsrc = data.qrcode || "";
          this.scanlogin(data.code);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.sidebar {
  position: fixed;
  right: 0px;
  top: 30%;
  z-index: 9999;

  table {
    height: 270px;
    text-align: center;
    background-color: white;
    border-spacing: inherit;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;

    div {
      width: 80px;
      text-align: center;
    }
  }
}

.sidebar a div .active {
  display: none;
}

.sidebar a div .active2 {
  display: none;
}

.sidebar a div .active3 {
  display: none;
}

.sidebar a div .active3 h3 {
  color: #29d19e;
}

.sidebar a div .active3 {
  padding-top: 10px;
  background-color: #fff;
}

.sidebar tr:hover div img {
  display: none;
}

.sidebar tr:hover .active {
  display: inline-block;
}

.sidebar tr:hover .active2 {
  display: block;
  width: 120px;
  height: 120px;
  position: absolute;
  right: 90px;
  top: 0;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.sidebar tr:hover .active3 {
  display: block;
  width: 120px;
  height: 190px;
  position: absolute;
  right: 90px;
  top: 0;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.2);
}

.sidebar a {
  text-align: center;
  display: block;
}

.sidebar tr:hover .active3 {
  color: #29d19e;
  border-radius: 2px;
  margin-top: 3px;
}

.sidebar table tr:hover {
  background-color: #29d19e;
}

.sidebar tr:hover {
  color: #fff;
  cursor: pointer;
}

.sidebar table tr,
td {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 5px;
  width: 80px;
  text-align: center;
  position: relative;
}

.sidebar tr a {
  color: #000;
}

.sidebar tr:hover a {
  color: #fff;
}

.sidebar table {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), -5px -5px 10px rgba(0, 0, 0, 0.1);
}

.sidebar a {
  text-align: center;
  display: block;
}

.sidebar tr:hover .active3 {
  color: #29d19e;
  border-radius: 2px;
  margin-top: 3px;
}

.sidebar table tr:hover {
  background-color: #29d19e;
}

.sidebar tr:hover {
  color: #fff;
  cursor: pointer;
}

.sidebar table tr,
td {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 5px;
  width: 80px;
  text-align: center;
  position: relative;
}

.sidebar tr a {
  color: #000;
}

.sidebar tr:hover a {
  color: #fff;
}

.sidebar table {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1), -5px -5px 10px rgba(0, 0, 0, 0.1);
}

.userName:hover {
  position: relative;

  .maskdiv {
    display: block;
  }
}

.maskdiv {
  display: none;
  width: 60px;
  position: absolute;
  left: -20px;
  top: 38px;
  z-index: 20;
  padding: 10px 20px;
  background-color: #fff;
  border: 1px solid #e3e3e3;

  span {
    display: block;
    color: #666;
  }
}

.qun {
  position: absolute;
  left: -20px;
  top: 38px;
  display: none;
  z-index: 20;
  padding: 10px 10px 0 10px;

  div {
    width: 125px;
    background-color: #fff;
    border: 1px solid #e3e3e3;

    a {
      color: #666;
      background-color: #fff;
      padding: 5px 8px;
      line-height: 20px;
      float: left;
    }

    a:hover {
      color: #1ab394;
    }
  }
}

.mask {
  display: none;
  width: 100px;
  z-index: 20;
  position: absolute;
  top: 38px;
  left: -20px;
}

.content {
  height: 100px;
  overflow: auto;
  width: 1200px;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  // position: relative;
  .header {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 100;
    width: 1200px;
    transform: translateX(-50%);

    .nav-0 {
      width: 560px;
      height: 38px;
      line-height: 38px;
      float: right;

      background: url("../../assets/firstPage/形状 5.png") no-repeat;
    }

    .nav-1 {
      .hov-1:hover {
        .mask {
          display: inline-block;
        }
      }

      .hov-0:hover {
        .qun {
          display: inline-block;
        }
      }

      .hov-2:hover {
        .mask {
          display: inline-block;
        }
      }

      .mask {
        display: none;
        width: 100px;
        z-index: 20;
        position: absolute;
        top: 38px;
        left: -20px;
      }

      span {
        cursor: pointer;
      }

      span:hover {
        color: #1ab394;
      }

      align-items: center;
      width: 450px;
      margin: 0 auto;
    }
  }

  .logo {
    // flex-grow: 1;
    // width: 100%;
    width: 700px;
    float: right;
    height: 52px;
    line-height: 52px;
    display: flex;
    justify-content: flex-end;

    img {
      vertical-align: middle;
    }
  }

  .clearbox {
    clear: both;
  }

  // /deep/ .logo {
  //   height: 60px;
  //   line-height: 60px;
  //   display: flex;
  //   justify-content: space-between;
  // }
  .nav {
    width: 855px;
    display: flex;

    // justify-content: space-around;
    .nav-none {
      // display: none;
      position: absolute;
      top: 20px;
    }

    span {
      cursor: pointer;
      padding: 0 20px;
    }

    .phone {
      margin-left: auto;
      position: relative;
    }

    .phone:hover {
      .mask {
        display: inline-block;
      }
    }

    // span:last-child {
    //   margin-left: auto;
    //   position: relative;
    // }
    span:hover {
      color: #1ab394;

      img {
        // background: #1ab394;
      }
    }

    .active {
      color: #1ab394;
    }
  }
}

.content-1 {
  .nav-1 {
    padding: 70px 0;
    display: flex;
    justify-content: space-around;
  }
}

/deep/.bottom {
  border-bottom: 1px solid;
}

/dep/.el-dialog__body {
  padding: 0px !important;
}

footer {
  margin-top: 30px;
  color: white;

  p {
    margin-bottom: 10px;
  }

  .f-sb {
    a {
      display: inline-block;
      padding: 5px 0;
    }
  }

  .f-sa {
    background-color: black;
  }

  a {
    color: white;
  }

  a:hover {
    color: white;
  }

  .img {
    width: 150px;
    height: 150px;
  }

  .tail {
    text-align: center;
    background-color: black;
    height: 40px;
    line-height: 40px;
  }
}
</style>